import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { Button, Modal } from "antd";
import "../../src/index.css";
import "../../src/App.scss";

import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import pluspng from "../assets/plus.png";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import axios from "axios";
import history from "../assets/history.png";
import InvoiceHistorySidebar from "./InvoiceHistorySidebar";
import generateTitle from "./generateTitle";
import { SortOrder } from "antd/es/table/interface";
import Loader from "./Loader";
import { apiUrl } from '../services/baseUrl';

const Logs = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logData, setLogData] = useState([]);
  const [logItem, setLogItem] = useState([]);
  const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
  const [delModalOpen, setIsModalOpen2] = useState(false);
  const [selectedInvoiceRow, setSelectedInvoiceRow] = useState(null);
  const [searchValue, setSearchValue] = useState<any>("");
  const [historyInvoiceData, setHistoryInvoiceData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [checkIndex, setCheckIndex] = useState();

  sessionStorage.editInvoiceID = "";
  sessionStorage.selectedQuote = "";
  sessionStorage.quoteStatus = "";

  function getLocalPart(email: string): string {
    const atIndex = email?.indexOf("@");
    if (atIndex === -1) {
      throw new Error("Invalid email address");
    }
    return email?.substring(0, atIndex);
  }

  function formatDate(input: string): string {
    const date = new Date(input);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format and return the date as DD-MM-YYYY
    return `${day}-${month}-${year}`;
  }

  // const splitRupees = (val: any) => {
  //   const number = parseFloat(val);
  //   if (isNaN(number)) {
  //     return 0;
  //   } else {
  //     return number.toLocaleString("en-IN", { maximumFractionDigits: 2 });
  //   }
  // };

  const splitRupees = (amount: any) => {
    // Ensure the amount is a number and remove any existing commas
    const parsedAmount = parseFloat(amount.toString().replace(/,/g, "")).toFixed(2);

    // Split into integer and decimal parts
    const parts = parsedAmount.split(".");

    // Format the integer part with commas for Indian numbering system
    const integerPart = parts[0];
    let lastThreeDigits = integerPart.substring(integerPart.length - 3);
    let otherDigits = integerPart.substring(0, integerPart.length - 3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }
    const formattedRupees = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    // Combine and return the formatted amount
    return "₹" + formattedRupees + "." + parts[1];
  };

  function getTotal(invoiceData: any) {
    try {
      const parsedData = JSON.parse(invoiceData);
      if (parsedData && parsedData.totals && parsedData.totals.Total) {
        return parsedData.totals.Total;
      } else {
        // throw new Error("Total not found in the provided invoice data");
      }
    } catch (error: any) {
      // console.error("Invalid JSON string or Total not found:", error.message);
      return "0"; // Return zero if there's an error
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const extractRtoValue = (data: any) => {
    if (data) {
      const parsedQuoteData = JSON.parse(data);
      return parsedQuoteData?.rtoValue || 'GWS';
    }
    return 'N/A';
  };

  // // Get
  const fetchData = async () => {
    setLoader(true);
    try {
      let url = apiUrl + "getInvoice.php";
      const roleID = sessionStorage.getItem("userRoleId");
      if (roleID === "1") {
      } else {
        const userID = sessionStorage.getItem("userID");
        url += `?user_id=${userID}`;
      }
      const response = await axios.get(url);
      console.log("Log Data:", response.data);
      const tempArray: any = response?.data?.map((item: any, index: any) => ({
        invoice_id: item?.invoice_id,
        key: index + 1,
        rep: item?.user_name,
        cname: item?.company_name,
        date: item?.create_on,
        value: getTotal(item?.invoice_data) || "0",
        type: extractRtoValue(item.quote_data),
        invoice_status: item?.invoice_status
      }));
      setLoader(false);
      setLogData(tempArray);
      setLogItem(tempArray);
    } catch (error) {
      setLoader(false);
      console.error("error fetching:", error);
    }
  };

  const commonRender = (text: any) => (
    <div>
      <p className="text-black font-semibold font-Inter">{splitRupees(text) || "0"}</p>
    </div>
  );

  // search

  const handleSearch = (event: any) => {
    const searchEvent = event?.target?.value;
    setSearchValue(searchEvent.toLowerCase());
    filterSearch(searchEvent.toLowerCase());
  };

  const filterSearch = (searchEvent: any) => {
    const searchResult = logItem.filter((item: any) => {
      const lowerCaseSearchEvent = searchEvent.toLowerCase();
      return (
        item?.rep?.toString().toLowerCase().includes(lowerCaseSearchEvent) ||
        item?.cname?.toString().toLowerCase().includes(lowerCaseSearchEvent) ||
        item?.date?.toString().toLowerCase().includes(lowerCaseSearchEvent) ||
        item?.value?.toString().toLowerCase().includes(lowerCaseSearchEvent)
      );
    });
    setLogData(searchResult);
    console.log(searchResult);
  };

  const handleViewHistory = (data: any) => {
    setSelectedInvoiceRow(data);
    setViewHistoryOpen(true);
  };
  const handleEditInvoice = (data: any) => {
    debugger;
    if (data.invoice_id) {
      sessionStorage.editInvoiceID = data.invoice_id;
      if (data.invoice_status == '0') {
        sessionStorage.quoteStatus = data.invoice_status;
        navigate("/porposal?isEdit=true&status=approve");
      } else {
        sessionStorage.quoteStatus = data.invoice_status;
        navigate("/porposal?isEdit=true");
      }
    }
  };
  const handleHideView = () => {
    setViewHistoryOpen(false);
  };

  const items = (record: any, index: any): MenuProps["items"] => [
    ...(record.invoice_status !== "2"
      ? [
        {
          key: "1",
          label: (
            <div
              className="flex  space-x-2 xl:space-x-3 text-[14px] py-1 pr-2"
              onClick={() => handleEditInvoice(record)}
            >
              <span className="material-symbols-outlined font-light text-[#545263] text-[18px] xl:text-[24px]">
                edit
              </span>
              <p className="font-normal text-[11px] xl:text-[14px] text-black font-[Poppins]">
                Edit
              </p>
            </div>
          ),
        },
      ]
      : []),
    // {
    //   key: "1",
    //   label: (
    //     <div
    //       className="flex  space-x-2 xl:space-x-3 text-[14px] py-1 pr-2"
    //       onClick={() => handleEditInvoice(record)}
    //     >
    //       <span className="material-symbols-outlined font-light text-[#545263] text-[18px] xl:text-[24px]">
    //         edit
    //       </span>
    //       <p className="font-normal text-[11px] xl:text-[14px] text-black font-[Poppins]">
    //         Edit
    //       </p>
    //     </div>
    //   ),
    // },
    {
      key: "2",
      label: (
        <div
          className="flex space-x-2 xl:space-x-3 pr-1 xl:pr-3 py-0 xl:py-1"
          onClick={() => {
            handleViewHistory(record);
            setCheckIndex(index);
          }}>
          <span className="material-symbols-outlined font-light text-[#545263] text-[18px] xl:text-[24px]">
            history
          </span>
          <p className="font-normal text-[11px] xl:text-[14px] text-black font-[Poppins]">
            Version History
          </p>
        </div>
      ),
    },

    // {
    //   key: "3",
    //   label: (
    //     <div
    //       className="flex space-x-2 xl:space-x-3 pr-1 xl:pr-3 py-0 xl:py-1"
    //       onClick={() => {
    //         handleViewHistory(record);
    //         setCheckIndex(index);
    //       }}
    //     >
    //       <span className="material-symbols-outlined font-light text-[#545263] text-[18px] xl:text-[24px]">
    //         history
    //       </span>
    //       <p className="font-normal text-[11px] xl:text-[14px] text-black font-[Poppins]">
    //         Approved
    //       </p>
    //     </div>
    //   ),
    // },
  ];

  const columns = [
    // {
    //   title: "S.no",
    //   dataIndex: "key",
    //   key: "key",
    //   width: "10%",
    //   render: (text: any, record: any) => <p className=""> {text}</p>,
    // },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "S.No",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "key",
      key: "key",
      defaultSortOrder: "ascend" as SortOrder,
      width: "10%",
      sorter: (a: any, b: any) => a?.key - b?.key, // Numerical comparison
      render: (text: any, record: any) => <p className=" s-no"> {text}</p>,
    },

    // {
    //   title: "Sales Rep",
    //   dataIndex: "rep",
    //   key: "rep",
    //   width: "20%",
    //   render: (text: any, record: any) => <p className="letter-capital"> {text}</p>,
    // },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Sales Rep",
          sortColumns?.find(({ column }: any) => column.key === "rep"),
        ),
      dataIndex: "rep",
      key: "rep",
      defaultSortOrder: "ascend" as SortOrder,
      width: "20%",
      sorter: (a: any, b: any) => a?.rep?.localeCompare(b?.rep),
      render: (text: any, record: any) => <p className="letter-capital"> {generateName(text)}</p>,
    },
    // {
    //   title: "Company Name",
    //   dataIndex: "cname",
    //   key: "cname",
    //   width: "20%",
    //   render: (text: any, record: any) => <p className="letter-capital"> {text}</p>,
    //   sorter: {
    //     compare: (a: any, b: any) => a.cname - b.cname,
    //     multiple: 1,
    //   },
    // },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Company Name",
          sortColumns?.find(({ column }: any) => column.key === "cname"),
        ),
      dataIndex: "cname",
      key: "cname",
      defaultSortOrder: "ascend" as SortOrder,
      width: "22%",
      sorter: (a: any, b: any) => a?.cname?.localeCompare(b?.cname),
      render: (text: any, record: any) => <p className="letter-capital leading-[13px] xl:leading-[20px]"> {text}</p>,
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   width: "20%",
    // },

    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Date",
          sortColumns?.find(({ column }: any) => column.key === "date"),
        ),
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "ascend" as SortOrder,
      width: "18%",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a?.date);
        const dateB = new Date(b?.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (text: any, record: any) => <p className="letter-capital"> {formatDate(text)}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Invoice Type",
          sortColumns?.find(({ column }: any) => column.key === "type"),
        ),
      dataIndex: "type",
      key: "type",
      defaultSortOrder: "ascend" as SortOrder,
      width: "20%",
      sorter: (a: any, b: any) => a?.rep?.localeCompare(b?.rep),
      render: (text: any, record: any) => <p className="letter-capital"> {generateName(text)}</p>,
    },

    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Value",
          sortColumns?.find(({ column }: any) => column.key === "value"),
        ),
      dataIndex: "value",
      key: "value",
      defaultSortOrder: "ascend" as SortOrder,
      width: "22%",
      sorter: (a: any, b: any) => a?.value - b?.value, // Numerical comparison
      render: commonRender,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Status",
          sortColumns?.find(({ column }: any) => column.key === "invoice_status"),
        ),
      dataIndex: "invoice_status",
      key: "invoice_status",
      defaultSortOrder: "ascend" as SortOrder,
      width: "22%",
      sorter: (a: any, b: any) => a?.invoice_status - b?.invoice_status, // Numerical comparison
      render: (text: any, record: any) => <p className="letter-capital leading-[13px] xl:leading-[20px]"> {setStatus(text)}</p>,
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: "0.5%",
      render: (text: any, record: any, index: any) => (
        <div className="flex jsk">
          <Dropdown menu={{ items: items(record, index) }} trigger={["click"]} placement="bottomRight">
            <p className={`${text?.toLowerCase()} name cursor-pointer font-Inter text-[16px] text-[#3A3A3A] `} onClick={handleDel}>
              {index !== checkIndex && <span className="material-symbols-outlined text-[19px] xl:text-[24px]">more_vert</span>}
            </p>
          </Dropdown>
        </div>
      ),
    },
  ];
  const generateName = (baseName: any) => {
    const nameParts = baseName?.split(".");
    const firstName = nameParts[0];
    return firstName;
  };
  const setStatus = (value: any) => {
    let res = 'Done';

    if (value == '0') {
      res = 'Approval pending';
    }
    else if (value == '1') {
      res = 'Done';
    }
    else if (value == '2') {
      res = 'Rejected';
    } else {
      res = 'Done';
    }
    return res;
  };
  const handleDel = () => {
    setIsModalOpen2(true);
  };

  const gotoProposalPage = () => {
    navigate("/porposal");
  };

  return (
    <div>
      <Header></Header>

      <div className="  mx-auto  w-[95%] xl:w-[97%] 2xl:w-[95%] mt-[110px] xl:mt-[120px]">
        <div className="flex">
          <div className={!viewHistoryOpen ? "w-full" : "w-[80%] pr-4"}>
            <div className="bg-gy pt-3">
              <div className="grid grid-cols-2 gap-6 align-center mb-3 xl:mb-5">
                <div className="...">
                  <h3>Log</h3>
                </div>
                <div className="...">
                  <div className="al-ser1">
                    <div>
                      <div className="flex px-2 py-2 rounded-md border-2 int w-full	 overflow-hidden  font-[sans-serif]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className=" w-[14px] xl:w-[24px] h-[18px] xl:h-[24px]" xmlns="http://www.w3.org/2000/svg">
                          <g id="vuesax/linear/search-normal">
                            <g id="search-normal">
                              <path
                                id="Vector"
                                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path id="Vector_2" d="M22 22L20 20" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                          </g>
                        </svg>
                        <input type="email" placeholder="Search" onChange={handleSearch} value={searchValue} className="w-full outline-none bg-transparent text-gray-600 text-[11px] xl:text-[14px]" />
                      </div>
                    </div>
                    <div>
                      {/* <a className="n-user flex align-center" href="https://example.com">
                        <span className="material-symbols-outlined">add</span> New Proposal
                      </a> */}
                      <Button className="n-user-newprop flex align-center" onClick={(e) => gotoProposalPage()}>
                        <span className="material-symbols-outlined text-[20px] xl:text-[24px] ">add</span> New Proposal
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                columns={columns}
                dataSource={logData}
                loading={loader}
                // pagination={{
                //   showSizeChanger: true,
                // }}
                pagination={{ pageSize: 20 }}
                rowClassName={(record, index) => (index === checkIndex ? "selected-row" : "")}
              />
            </div>
          </div>

          {viewHistoryOpen && (
            <InvoiceHistorySidebar
              viewHistoryOpen={viewHistoryOpen}
              handleHideView={handleHideView}
              setCheckIndex={setCheckIndex}
              selectedInvoiceRow={selectedInvoiceRow}
              historyInvoiceData={historyInvoiceData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Logs;
